import axiosApi from '@/api/axiosApi';

export const save = (data) => {
    return axiosApi({
        method: 'POST',
        url: `/intserv/other-form/save`,
        data
    })
}

export const remove = (data) => {
    return axiosApi({
        method: 'POST',
        url: `/intserv/other-form/delete`,
        data
    })
}

export const selectListByInfo = (data) => {
    return axiosApi({
        method: 'POST',
        url: `/intserv/other-form/selectListByInfo`,
        data
    })
}